import {createRouter, createWebHistory} from "vue-router"
// import {store} from "./../store/index"

const routes = [
    {
        name: "auth",
        path: "/auth",
        component: () => import("../components/layouts/Auth"),
        children: [
            {
                name: "userLogin",
                path: "user/login",
                component: () => import("../views/system/Login")
            },
            {
                name: "streamerLogin",
                path: "streamer/login",
                component: () => import("../views/streamer/Login")
            },
            {
                name: "streamerRegister",
                path: "streamer/register",
                component: () => import("../views/streamer/Register")
            },
            {
                name: "userRegister",
                path: "user/register",
                component: () => import("../views/system/Register")
            },
            {
                name: "customerLogin",
                path: "customer/login",
                component: () => import("../views/Customer/Login")
            },
            {
                name: "customerRegister",
                path: "customer/register",
                component: () => import("../views/Customer/Register")
            },
            {
                path: "forgot-password/:type",
                name:"forgotPassword",
                component: () => import('../views/common/ForgotPassword')
            },
            {
                name:"setPassword",
                path: "set-password",
                component: () => import('../views/common/SetPassword')
            },
            {
                name:"verifyEmail",
                path: "verify-email",
                component: () => import('../views/common/VerifyEmail')
            }
        ]
    },
    {
        name: "LiveSale",
        path: "/",
        component: () =>
            import ("../components/layouts/Customer"),
        children: [
            {
                name: "main",
                path: "/",
                component: () =>
                    import ("../views/Customer/LiveSale"),
            },
            {
                name: "LiveSale",
                path: "/:id",
                component: () =>
                    import ("../views/Customer/LiveSale"),
            },
            {
                name: "LiveSaleByEventCode",
                path: "event/:eventCode",
                component: () =>
                    import ("../views/Customer/LiveSale"),
            },
            {
                name: "customerAccount",
                path: "/account/:id",
                component: () =>
                    import ("../views/Customer/Account"),
            },
            {
                name: "shopifyAccount",
                path: "shopify/account",
                component: () =>
                    import ("../views/system/ShopifyAccount"),
            },
            {
                name: "record",
                path: "/record/:id",
                component: () =>
                    import ("../views/theme/Record"),
            }
            ,
            {
                name: "kvkk",
                path: "/kvkk",
                component: () =>
                    import ("../views/common/KVKK"),
            },
            {
                name: "costCalculator",
                path: "/packet/calculator",
                component: () =>
                    import ("../views/common/Calculator"),
            },
            {
                name: "Packets",
                path: "/packet/packet-list",
                component: () =>
                    import ("../views/common/PacketList"),
            },
            {
                name: "loading-tsoft-iframe",
                path: "/loading/tsoft",
                component: () =>
                    import ("../views/loading/tsoft"),
            }

        ]
    },
    {
        name: "User dashboard",
        path: "/user",
        component: () =>
            import ("../components/layouts/Dashboard"),
        beforeEnter: (to) => {

            if (to.query.auth_token !== undefined && to.query.expired_time !== undefined){
                store.dispatch('userLogin',jwt_decode(to.query.auth_token));
                localStorage.setItem('token',to.query.auth_token)
                localStorage.expiredTime = to.query.expired_time;
                localStorage.rememberMe = true;
            }

            // eslint-disable-next-line no-unreachable
            if(localStorage.token === undefined || localStorage.token == "undefined") {//local storageden  string undefined dönüyor
                if (window.location.pathname != "/auth/user/login") {
                    window.location.replace("/auth/user/login");
                }
            }
        },
        children: [
            {
                name:"userDashboard",
                path: "home",
                component: () =>
                    import ("../views/system/Home"),
            },
            {
                name:"payment-result",
                path: "payment",
                component: () => import("../views/common/Payment")
            },
            {
                name:"dashboard",
                path: "",
                component: () =>
                    import ("../views/system/Home"),
            },
            {
                path: "events",
                component: () =>
                    import ("../views/theme/Events"),
            },
            {
                name: "LiveSaleForUser",
                path: "live/:id",
                component: () =>
                    import ("../views/theme/Live"),
            },
            {
                name: "analysis",
                path: "analysis",
                component: () =>
                    import ("../views/common/Analysis"),
            },
            {
                name: "analysisDetail",
                path: "analysis/:id",
                component: () =>
                    import ("../views/common/AnalysisDetail"),
            },
            {
                name: "userAccount",
                path: "account/:id",
                component: () =>
                    import ("../views/system/Account"),
            },
            {
                name: "spendingLog",
                path: "spending-log",
                component: () =>
                    import ("../views/common/SpendLog"),
            },
            {
                name: "PaymentLog",
                path: "payment-log",
                component: () =>
                    import ("../views/common/PaymentLogs"),
            },
            {
                name: "video-details",
                path: "video-details",
                component: () =>
                    import ("../views/common/Videos/VideoDetails"),
            },
            {
                name: "add-new-video",
                path: "add-new-video",
                component: () =>
                    import ("../views/common/Videos/AddNewVideo"),
            },
            {
                name: "recorded-streams",
                path: "recorded-streams",
                component: () =>
                    import ("../views/common/Videos/RecordedStreams"),
            },
            {
                name: "recorded-events",
                path: "recorded-events",
                component: () =>
                    import ("../views/common/Videos/Events"),
            }

        ]
    },
    {
        name:"completeAccount",
        path: "/streamer/complete-account/:token",
        component: () => import("../views/streamer/CompleteAccount")
    },
    {
        name: "Streamer dashboard",
        path: "/streamer",
        component: () =>
            import ("../components/layouts/Streamer"),
        beforeEnter: () => {
            // if (to.query.auth_token !== undefined && to.query.expired_time !== undefined){
            //     store.dispatch('userLogin',jwt_decode(to.query.auth_token));
            //     localStorage.setItem('token',to.query.auth_token)
            //     localStorage.expiredTime = to.query.expired_time;
            //     localStorage.rememberMe = true;
            // }

            // eslint-disable-next-line no-unreachable
            if(localStorage.streamerToken === undefined || localStorage.streamerToken == "undefined") {//local storageden  string undefined dönüyor
                if (window.location.pathname != "/auth/streamer/login") {
                    window.location.replace("/auth/streamer/login");
                }
            }
        },
        children: [
            {
                path: "",
                component: () =>
                    import ("../views/system/Home"),
            },
            {
                path: "home",
                component: () =>
                    import ("../views/system/Home"),
            },
            {
                path: "analysis",
                component: () =>
                    import ("../views/common/Analysis"),
            },
            {
                path: "events",
                component: () =>
                    import ("../views/theme/Events"),
            },
            {
                path: "analysis/:id",
                component: () =>
                    import ("../views/common/AnalysisDetail"),
            },
            {
                name: "LiveSaleForStreamer",
                path: "live/:id",
                component: () =>
                    import ("../views/theme/Live"),
            }

        ]
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes
});
import store from "../store/index";
import {appAxios} from "@/utils/appAxios";
import jwt_decode from "jwt-decode";
router.beforeEach( async () => {

    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };

    if (getUrlParameter('token') !== false && getUrlParameter('expiredTime') !== false){
        store.dispatch('userLogin',jwt_decode(getUrlParameter('token')));
        localStorage.token = getUrlParameter('token');
        localStorage.expiredTime = getUrlParameter('expiredTime');
        store.state.is_logged= true;
    }

    if (getUrlParameter('customerToken') !== false && getUrlParameter('expiredTime') !== false){
        store.dispatch('customerLogin',jwt_decode(getUrlParameter('customerToken')));
        localStorage.customerToken= getUrlParameter('customerToken');
        localStorage.customerExpiredTime = getUrlParameter('expiredTime');
    }
    if(localStorage.customerToken !== undefined || localStorage.customerToken !== "undefined"){//local storageden  string undefined dönüyor
        if (localStorage.customerRememberMe==="true" && localStorage.customerExpiredTime<Math.floor(Date.now() / 1000)){
            console.log('REFRESH TOKEN')
            await appAxios.post('/refreshToken',{
                "token" : localStorage.customerToken
            }).then(response =>{
                localStorage.customerToken = response.data.data.jwt;
                localStorage.customerExpiredTime = response.data.data.expiredTime;
            }).catch(()=>{
                localStorage.customerToken = undefined;
                store.state.is_customer_logged= false;
                store.state.customer= [];
            });
        }

    }
    if (localStorage.customerExpiredTime === "undefined" || localStorage.customerExpiredTime<Math.floor(Date.now() / 1000)){
        localStorage.customerToken = undefined;
        localStorage.customerRememberMe = undefined;
        localStorage.customerExpiredTime = undefined;
        store.state.is_customer_logged= false;
        store.state.customer= [];
        localStorage.clear()
        // window.location.replace("/auth/customer/login");
    }

    if(localStorage.token !== undefined || localStorage.token !== "undefined"){//local storageden  string undefined dönüyor
        if (localStorage.rememberMe===true && localStorage.expiredTime<Math.floor(Date.now() / 1000)){
            console.log('REFRESH TOKEN')
            await appAxios.post('/refreshToken',{
                "token" : localStorage.token
            }).then(response =>{
                localStorage.token = response.data.data.jwt;
                localStorage.expiredTime = response.data.data.expiredTime;
            }).catch(()=>{
                localStorage.token = undefined;
                store.state.is_logged= false;
            });
        }

    }
    if ( localStorage.expiredTime === "undefined" || localStorage.expiredTime<Math.floor(Date.now() / 1000)){
        localStorage.token = undefined;
        store.state.is_logged= false;
        localStorage.clear()
        // window.location.replace("/auth/user/login");
    }

})
export default router;