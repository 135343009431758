import { createApp } from 'vue'
import App from './App.vue'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import './assets/output.css'
import './assets/main.css'
import store from "./store";
import router from "./router";
import {appAxios} from "@/utils/appAxios";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import i18n from "@/plugins/i18n";
import VueSweetalert2 from 'vue-sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.css';
import LoadScript from "vue-plugin-load-script";

const app = createApp(App);



library.add(fas, fab);

app.config.globalProperties.$appAxios = appAxios;
app.use(LoadScript);
app.use(store);
app.use(Antd);
app.use(router);
app.use(i18n);
app.use(VueSweetalert2);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");