import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {appAxios} from "@/utils/appAxios";

export default createStore({
    plugins: [createPersistedState()],
    state: {
        channelType:'',
        iframe:false,
        tsoft_iframe:false,
        eventId:'',
        is_logged: false,
        liveEvents:[],
        records:[],
        emptyEvent:false,
        showChat: true,
        is_customer_logged : false,
        products:[],
        userProducts:[],
        liveVideo:[],
        layout:'customer',
        customer:[],
        user:[],
        customer_token: "",
        eventList : [],
        campaign : {
            show:false,
            image:''
        },
        totalClick:"-",
        totalComment:"-",
        totalLike:"-",
        totalViewer:"-",
        totalClickForHeader:"-",
        totalCommentForHeader:"-",
        totalLikeForHeader:"-",
        totalViewerForHeader:"-",
        singularLikeForHeader:"-",
        totalCreditForHeader:"-",
        isPlaying:false,
        highlightProduct:[],
        eventDrawer: false,
        pastEvents:[],
        futureEvents:[],
        futureEventsPaging: {total: 0, limit: 10, page: 1},
        pastEventsPaging: {total: 0, limit: 10, page: 1},
        activeEventTab: 2,
        selectedEvent: {},
        campaignDrawer: false,
        activeCampaignTab: "1",
        allCampaigns: [],
        allCampaignsPaging : {total: 0, limit: 10, page: 1},
        eventCampaigns:[],
        eventCampaignsTotal:0,
        influencerDrawer:false,
        allInfluencers:[],
        allInfluencersPaging:{total:10,limit:10,page:1},
        allProductsDrawer:false,
        allProducts : [],
        allProductsPaging: {total:10,limit:10,page:1,startCursor:null,endCursor:null,hasNextPage:false,hasPreviousPage:false},
        eventProducts:[],
        upcomingEvents:[],
        eventProductsTotal:0,
        // eventDrawerType:null,
        editedEvent:null,
        editEventDrawer:false,
        spinProduct:false,
        spinCampaign:false,
        is_streamer_logged:false,
        streamer:{},
        viewerCount:0
        // eventCampaignsPaging: {total: 0, limit: 2, page: 1},

    },
    getters:{
        getEventCampaignsIds: (state) => (id) => {
            return state.eventCampaigns.find(campaign => campaign.id === id) !== undefined
        },
        getSelectedEvent:(state) => {
            return Object.keys(state.selectedEvent).length === 0 ? false : state.selectedEvent
        },
        getEventProductsIds:(state) => (id) => {
            return state.eventProducts.find(product => product.product_id == id) !== undefined
        }

    },
    mutations: {
        setCustomerVerification(state, payload){
            state.customer.verified = payload
        },
        setUserVerification(state, payload){
            state.user.verified = payload
        },
        customerLogin(state, payload) {
            state.customer = payload;
            state.is_customer_logged = true;
        },
        streamerLogin(state, payload) {
            state.streamer = payload;
            state.is_streamer_logged = true;
        },
        userLogin(state, payload) {
            state.is_logged = true;
            state.user = payload
        },
        logoutCustomer() {
            this.state.is_customer_logged = false;
            this.state.customer = [];
            localStorage.clear()
        },
        logout() {
            this.state.is_logged = false;
            localStorage.clear()
            window.location.reload();
        },
        logoutStreamer() {
            this.state.is_streamer_logged = false;
            localStorage.clear()
            window.location.reload();
        },
        setPastEvents(state, payload) {
            state.pastEvents = payload
        },
        setFutureEvents(state, payload) {
            state.futureEvents = payload
        },
        setActiveEventTab(state, payload) {
            state.activeEventTab = payload
        },
        setPastEventsPaging(state, payload) {
            state.pastEventsPaging.total = payload.total
            state.pastEventsPaging.limit = payload.limit
            state.pastEventsPaging.page = payload.page
        },
        setFutureEventsPaging(state, payload) {
            state.futureEventsPaging.total = payload.total
            state.futureEventsPaging.limit = payload.limit
            state.futureEventsPaging.page = payload.page
        },
        setSelectedEvent(state, payload) {
            state.selectedEvent = payload
        },
        setEventDrawer(state, payload) {
            state.eventDrawer = payload
        },
        setCampaignDrawer(state, payload) {
            state.campaignDrawer = payload
        },
        setAllCampaigns(state, payload) {
            state.allCampaigns = payload
        },
        setActiveCampaignTab(state, payload) {
            state.activeCampaignTab = payload
        },
        setAllCampaignsPaging(state, payload) {
            state.allCampaignsPaging.total = payload.total
            state.allCampaignsPaging.limit = payload.limit
            state.allCampaignsPaging.page = payload.page
        },
        setEventCampaigns(state, payload) {
            state.eventCampaigns = payload
        },
        setEventCampaignsTotal(state, payload) {
            state.eventCampaignsTotal = payload
        },
        setInfluencerDrawer(state, payload) {
            state.influencerDrawer = payload
        },
        setAllInfluencers(state, payload) {
            state.allInfluencers = payload
        },
        setAllInfluencersPaging(state, payload) {
            state.allInfluencersPaging= {total:payload.total,page:payload.page,limit: payload.limit}
        },
        setAllProductsDrawer(state, payload) {
            state.allProductsDrawer= payload
        },
        setAllProducts(state,payload){
            state.allProducts = payload
        },
        setAllProductsPaging(state,payload){
            state.allProductsPaging = payload
        },
        setEventProducts(state,payload){
            state.eventProducts = payload
        },
        setEventProductsTotal(state,payload){
            state.eventProductsTotal = payload
        },
        // setEventDrawerType(state,payload){
        //     state.eventDrawerType = payload
        // },
        setEditedEvent(state,payload){
            state.editedEvent = payload
        },
        setEditEventDrawer(state, payload){
            state.editEventDrawer = payload
        }
        // setEventCampaignsPaging(state, payload) {
        //     state.eventCampaignsPaging.total = payload.total
        //     state.eventCampaignsPaging.limit = payload.limit
        //     state.eventCampaignsPaging.page = payload.page
        // }
    },
    actions: {
        customerLogin(context, payload) {
            context.commit('customerLogin', payload)
        },
        streamerLogin(context, payload) {
            context.commit('streamerLogin', payload)
        },
        userLogin(context, payload) {
            context.commit('userLogin', payload)
        },
        setPastEvents({commit}) {

            let url ='';
            let token = '';
            if (this.state.layout !=='streamer'){
                url = '/getEvents'
                token = localStorage.getItem("token")
            }else {
                url = '/streamer/getEvents'
                token = localStorage.getItem("streamerToken")
            }
           appAxios.get(url, {
                headers: {
                    Authorization: [`Bearer ${token}`]
                },
                params: {
                    type: 'past',
                    page: this.state.pastEventsPaging.page,
                    limit: this.state.pastEventsPaging.limit
                }
            }).then((response) => {
               response.data.data.events.forEach(event => {
                   event.statistics = JSON.parse(event.statistics)
               })
                commit('setPastEvents', response.data.data.events)
                commit('setPastEventsPaging', {total:response.data.data.total,page:response.data.data.page,limit:response.data.data.limit})

            }).catch(error => {

                    switch (error.response.status) {

                        case 500:
                            this.$swal({
                                icon: 'error',
                                title: this.$t('errors.system-error.title'),
                                html: this.$t('errors.system-error.text')
                            })
                            break

                        case 422:

                            this.$swal({
                                icon: 'error',
                                text: this.$t('errors.validation-error.title')
                            })
                            // eslint-disable-next-line no-case-declarations
                            let obj = JSON.parse(error.response.data.error.description);

                            for (const [key, value] of Object.entries(obj)) {

                                this.errors[key].status = true
                                this.errors[key].message = value

                            }
                            break

                        default:

                            this.$swal({
                                icon: 'error',
                                text: error.response.data.error.description
                            })
                            break
                    }
                }
            )
        },

        setFutureEvents({commit}) {
            let url ='';
            let token = '';
            if (this.state.layout !=='streamer'){
                url = '/getEvents'
                token = localStorage.getItem("token")
            }else {
                url = '/streamer/getEvents'
                token = localStorage.getItem("streamerToken")
            }
            appAxios.get(url, {
                headers: {
                    Authorization: [`Bearer ${token}`]
                },
                params: {
                    type: 'future',
                    page: this.state.futureEventsPaging.page,
                    limit: this.state.futureEventsPaging.limit
                }
            }).then((response) => {
                commit('setFutureEvents', response.data.data.events)
                commit('setFutureEventsPaging', {total:response.data.data.total,page:response.data.data.page,limit:response.data.data.limit})

            }).catch(error => {

                    switch (error.response.status) {

                        case 500:
                            this.$swal({
                                icon: 'error',
                                title: this.$t('errors.system-error.title'),
                                html: this.$t('errors.system-error.text')
                            })
                            break

                        case 422:

                            this.$swal({
                                icon: 'error',
                                text: this.$t('errors.validation-error.title')
                            })
                            // eslint-disable-next-line no-case-declarations
                            let obj = JSON.parse(error.response.data.error.description);

                            for (const [key, value] of Object.entries(obj)) {

                                this.errors[key].status = true
                                this.errors[key].message = value

                            }
                            break

                        default:

                            this.$swal({
                                icon: 'error',
                                text: error.response.data.error.description
                            })
                            break
                    }
                }
            )
        },
        setAllCampaigns({commit}) {
            if (this.state.layout === 'streamer'){
                return false
            }

           appAxios.get('/getCampaign', {
                headers: {
                    Authorization: [`Bearer ${localStorage.getItem("token")}`]
                },
                params: {
                    page: this.state.allCampaignsPaging.page,
                    limit: this.state.allCampaignsPaging.limit
                }
            }).then((response) => {
                commit('setAllCampaigns', response.data.data.campaigns)
                commit('setAllCampaignsPaging', {total:response.data.data.total,page:response.data.data.page,limit:response.data.data.limit})

            }).catch(error => {

                    switch (error.response.status) {

                        case 500:
                            this.$swal({
                                icon: 'error',
                                title: this.$t('errors.system-error.title'),
                                html: this.$t('errors.system-error.text')
                            })
                            break

                        case 422:

                            this.$swal({
                                icon: 'error',
                                text: this.$t('errors.validation-error.title')
                            })
                            // eslint-disable-next-line no-case-declarations
                            let obj = JSON.parse(error.response.data.error.description);

                            for (const [key, value] of Object.entries(obj)) {

                                this.errors[key].status = true
                                this.errors[key].message = value

                            }
                            break

                        default:

                            this.$swal({
                                icon: 'error',
                                text: error.response.data.error.description
                            })
                            break
                    }
                }
            )
        },
        setEventCampaigns({commit}) {
            this.state.spinCampaign = true
            let url ='';
            let token = '';
            if (this.state.layout !=='streamer'){
                url = '/getEventCampaigns'
                token = localStorage.getItem("token")
            }else {
                url = '/streamer/getEventCampaigns'
                token = localStorage.getItem("streamerToken")
            }

            appAxios.get(url, {
                headers: {
                    Authorization: [`Bearer ${token}`]
                },
                params: {
                    eventId: this.state.selectedEvent.id,
                    // page: this.state.eventCampaignsPaging.page,
                    // limit: this.state.eventCampaignsPaging.limit
                }
            }).then((response) => {
                this.state.spinCampaign = false

                commit('setEventCampaigns', response.data.data.eventCampaigns)
                commit('setEventCampaignsTotal', response.data.data.total)
                // commit('setEventCampaignsPaging', {total:response.data.data.total,page:response.data.data.page,limit:response.data.data.limit})

            }).catch(error => {

                    switch (error.response.status) {

                        case 500:
                            this.$swal({
                                icon: 'error',
                                title: this.$t('errors.system-error.title'),
                                html: this.$t('errors.system-error.text')
                            })
                            break

                        case 422:

                            this.$swal({
                                icon: 'error',
                                text: this.$t('errors.validation-error.title')
                            })
                            // eslint-disable-next-line no-case-declarations
                            let obj = JSON.parse(error.response.data.error.description);

                            for (const [key, value] of Object.entries(obj)) {

                                this.errors[key].status = true
                                this.errors[key].message = value

                            }
                            break

                        default:

                            this.$swal({
                                icon: 'error',
                                text: error.response.data.error.description
                            })
                            break
                    }
                }
            )
        },
        setAllInfluencers({commit}) {

            appAxios.get('/getStreamers', {
                headers: {
                    Authorization: [`Bearer ${localStorage.getItem("token")}`]
                },
                params: {
                    page: this.state.allInfluencersPaging.page,
                    limit: this.state.allInfluencersPaging.limit
                }
            }).then((response) => {
                commit('setAllInfluencers', response.data.data.streamers)
                commit('setAllInfluencersPaging', {total:response.data.data.total,page:response.data.data.page,limit:response.data.data.limit})

            }).catch(error => {

                    switch (error.response.status) {

                        case 500:
                            this.$swal({
                                icon: 'error',
                                title: this.$t('errors.system-error.title'),
                                html: this.$t('errors.system-error.text')
                            })
                            break

                        case 422:

                            this.$swal({
                                icon: 'error',
                                text: this.$t('errors.validation-error.title')
                            })
                            // eslint-disable-next-line no-case-declarations
                            let obj = JSON.parse(error.response.data.error.description);

                            for (const [key, value] of Object.entries(obj)) {

                                this.errors[key].status = true
                                this.errors[key].message = value

                            }
                            break

                        default:

                            this.$swal({
                                icon: 'error',
                                text: error.response.data.error.description
                            })
                            break
                    }
                }
            )
        },
        setAllProducts({commit}, query = ""){
            console.log(this.state.selectedEvent)
            let platformId
            if (typeof this.state.selectedEvent.platform !== 'undefined'){
                platformId = this.state.selectedEvent.platform.id
            }else {
                platformId = this.state.selectedEvent.platform_id
            }

            return appAxios.get('/getProducts', {
                headers: {
                    Authorization: [`Bearer ${localStorage.getItem("token")}`]
                },
                params: {
                    platform_id: platformId,
                    query: query,
                    page: this.state.allProductsPaging.page,
                    limit: this.state.allProductsPaging.limit,
                    endCursor:this.state.allProductsPaging.endCursor,
                    startCursor:this.state.allProductsPaging.startCursor
                }
            }).then((response) => {

                commit('setAllProducts', response.data.data.products)
                 if(typeof this.state.selectedEvent.platform  !== 'undefined' && this.state.selectedEvent.platform.ecommerce_provider_id == 3){
                     commit('setAllProductsPaging', {hasNextPage:response.data.data.hasNextPage,hasPreviousPage:response.data.data.hasPreviousPage,limit:response.data.data.limit,startCursor: response.data.data.startCursor,endCursor: response.data.data.endCursor})

                 }else {
                     commit('setAllProductsPaging', {total:response.data.data.total,page:response.data.data.page,limit:response.data.data.limit})

                 }

            }).catch(error => {

                    switch (error.response.status) {

                        case 500:
                            this.$swal({
                                icon: 'error',
                                title: this.$t('errors.system-error.title'),
                                html: this.$t('errors.system-error.text')
                            })
                            break

                        case 422:

                            this.$swal({
                                icon: 'error',
                                text: this.$t('errors.validation-error.title')
                            })
                            // eslint-disable-next-line no-case-declarations
                            let obj = JSON.parse(error.response.data.error.description);

                            for (const [key, value] of Object.entries(obj)) {

                                this.errors[key].status = true
                                this.errors[key].message = value

                            }
                            break

                        default:

                            this.$swal({
                                icon: 'error',
                                text: error.response.data.error.description
                            })
                            break
                    }
                }
            )
        },
        setEventProducts({commit}){

            let url ='';
            let token = '';
            if (this.state.layout !=='streamer'){
                this.state.spinProduct = true
                url = '/getEventProducts'
                token = localStorage.getItem("token")
            }else {
                url = '/streamer/getEventProducts'
                token = localStorage.getItem("streamerToken")
            }

            return appAxios.get(url, {
                headers: {
                    Authorization: [`Bearer ${token}`]
                },
                params: {
                    // page: this.state.allProductsPaging.page,
                    // limit: this.state.allProductsPaging.limit
                    eventId: this.state.selectedEvent.id
                }
            }).then((response) => {
                commit('setEventProducts', response.data.data.products)
                commit('setEventProductsTotal', response.data.data.total)
                this.state.spinProduct = false
                // commit('setAllProductsPaging', {total:response.data.data.total,page:response.data.data.page,limit:response.data.data.limit})

            }).catch(error => {

                    switch (error.response.status) {

                        case 500:
                            this.$swal({
                                icon: 'error',
                                title: this.$t('errors.system-error.title'),
                                html: this.$t('errors.system-error.text')
                            })
                            break

                        case 422:

                            this.$swal({
                                icon: 'error',
                                text: this.$t('errors.validation-error.title')
                            })
                            // eslint-disable-next-line no-case-declarations
                            let obj = JSON.parse(error.response.data.error.description);

                            for (const [key, value] of Object.entries(obj)) {

                                this.errors[key].status = true
                                this.errors[key].message = value

                            }
                            break

                        default:

                            this.$swal({
                                icon: 'error',
                                text: error.response.data.error.description
                            })
                            break
                    }
                }
            )
        },
        // eslint-disable-next-line no-unused-vars
        removeProductFromEvent({commit},id){

            appAxios.post('/deleteProductFromEvent', {
                productId: id,
                eventId: this.state.selectedEvent.id,
            }, {
                headers: {
                    Authorization: [`Bearer ${localStorage.getItem("token")}`]
                }
            }).then(() => {
                this.dispatch('setEventProducts')
                // commit('setAllProductsPaging', {total:response.data.data.total,page:response.data.data.page,limit:response.data.data.limit})

            }).catch(error => {

                    switch (error.response.status) {

                        case 500:
                            this.$swal({
                                icon: 'error',
                                title: this.$t('errors.system-error.title'),
                                html: this.$t('errors.system-error.text')
                            })
                            break

                        case 422:

                            this.$swal({
                                icon: 'error',
                                text: this.$t('errors.validation-error.title')
                            })
                            // eslint-disable-next-line no-case-declarations
                            let obj = JSON.parse(error.response.data.error.description);

                            for (const [key, value] of Object.entries(obj)) {

                                this.errors[key].status = true
                                this.errors[key].message = value

                            }
                            break

                        default:

                            this.$swal({
                                icon: 'error',
                                text: error.response.data.error.description
                            })
                            break
                    }
                }
            )
        }

    },

    modules: {}
});