<template>
  <!-- <router-view /> -->
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>

import trTR from 'ant-design-vue/es/locale/tr_TR';

export default {
  name: 'App',
  data() {
    return {
      locale: trTR
    }
  },

}
</script>

<style>

</style>