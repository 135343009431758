import tr from './locales/tr'
import en from './locales/en'

// import {tr,en} from './locales'
import {createI18n} from "vue-i18n/dist/vue-i18n.esm-bundler";

const messages = {
    tr, en
};

if( !localStorage.getItem("lang") ){
    let browserLang = Object.keys( messages ).find( (lang) => { return lang == navigator.language.substring(0,2) })
    browserLang ? localStorage.setItem( "lang", browserLang ) : localStorage.setItem( "lang", "tr" )
}

const i18n = createI18n({
    locale: localStorage.lang, // localStorage.lang set locale
    fallbackLocale: 'en', // set fallback locale
    messages
})

export default i18n




